import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Environment: {process.env.REACT_APP_ENVIRONMENT}</h1>
        <h2>Token: {process.env.REACT_APP_SECRET_TOKEN}</h2>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
      </header>
    </div>
  );
}

export default App;
